<script setup lang="ts">
import { MAX_DEFAULT_BUDGET, MIN_DEFAULT_BUDGET } from '@/helpers/priceHelper'
import { topFacetsAdmin, topFacetsClient } from '~/helpers/search/topFacets'
import type BaseInputNumber from '~/components/BaseForm/components/BaseInputNumber.vue'
import type { NumericFilter, SearchFacetTuple } from '~/types/search/types'

const { userIsAdmin, userIsPartner } = useAuth()
const { trackEvent } = useTracking()
const { isNewHeader } = useHeaderV2()
const { filtersCount } = useSearchFiltersCount()

const props = withDefaults(
  defineProps<{
    bathroomsQuantity?: number
    bedroomsQuantity?: number
    capacityQuantity?: number
    facetFilters?: Record<string, string[]>
    facets?: Record<string, Record<string, number>>
    loadingFacets?: boolean
    modelValue?: boolean
    nbHits?: number
    maxBudget?: string
    minBudget?: string
  }>(),
  {
    bathroomsQuantity: 0,
    bedroomsQuantity: 0,
    capacityQuantity: 0,
    facetFilters: () => ({}),
    facets: () => ({}),
    loadingFacets: false,
    modelValue: false,
    nbHits: 1,
    maxBudget: MAX_DEFAULT_BUDGET,
    minBudget: MIN_DEFAULT_BUDGET,
  },
)
const emits = defineEmits<{
  'change-facet': SearchFacetTuple
  'change-exclu': [boolean]
  'clear-all': []
  'clear-filter-numeric': [NumericFilter]
  'clear-facets-other': []
  'refine-filter-numeric': [NumericFilter, number]
  'toggle-filters': []
  'update:model-value': [boolean]
  'update-budget': [string, string]
}>()

const { locale, t } = useI18n()

const orderFiltersFacetClient = [
  { key: 5, open: true }, // In the spotlight
  { key: 10, open: true }, // Swimming pool
  { key: 1, open: true }, // Property type
  { key: 2, open: true }, // Environment
  { key: 7, open: true }, // Well-being & Sport
  { key: 4, open: true }, // Amenities
  { key: 3, open: true }, // Property features
]

const orderFiltersFacetAdmin = [
  { key: 1, open: true }, // Property type
  { key: 11, open: true }, // Swimming pool
  { key: 8, open: true }, // Conciergerie
  { key: 9, open: true }, // Licence
  { key: 5, open: true }, // In the spotlight
  { key: 2, open: false }, // Environment
  { key: 7, open: false }, // Well-being & Sport
  { key: 4, open: false }, // Amenities
  { key: 3, open: false }, // Property features
]

const groupFilterRefs = ref<
  Record<NumericFilter, InstanceType<typeof BaseInputNumber> | null>
>({ capacity: null, bedrooms: null, bathrooms: null })

const group = reactive<
  Record<NumericFilter, { category: string; icon: IconPath; count: number }>
>({
  capacity: {
    category: t('search.capacity'),
    icon: 'group',
    count: props.capacityQuantity,
  },
  bedrooms: {
    category: t('search.bedrooms'),
    icon: 'bed',
    count: props.bedroomsQuantity,
  },
  bathrooms: {
    category: t('search.bathrooms'),
    icon: 'bathroom',
    count: props.bathroomsQuantity,
  },
})

const changeFacet = (...args: SearchFacetTuple) => {
  emits('change-facet', ...args)
}
const clearFacetsOther = () => {
  emits('clear-facets-other')
}

const clearAll = () => {
  trackEvent({ event: 'reset_filter' })
  emits('clear-all')

  Object.keys(group).forEach((k) => {
    const key = k as NumericFilter
    group[key].count = 0
    if (groupFilterRefs.value[key]) {
      groupFilterRefs.value[key]!.inputValue = 0
    }
  })
}

const refineFilterNumeric = (attribute: NumericFilter, value: number) => {
  if (value === 0) {
    emits('clear-filter-numeric', attribute)
  } else {
    emits('refine-filter-numeric', attribute, value)
  }
}

const formatFacet = (key: number) => {
  const tag = `searchable_tags.${key}.${locale.value}`

  if (props.facets[tag]) {
    const values = Object.keys(props.facets[tag]).map((value) => {
      const facetQuery = `${tag}: ${value}`

      return {
        value,
        checked: props.facetFilters[key]?.includes(facetQuery) || false,
      }
    })

    return {
      key,
      tag,
      values,
    }
  }
}

const changeExclu = (isExclu: boolean) => {
  emits('change-exclu', isExclu)
}

const updateBudget = (maxBudget: string, minBudget: string) => {
  emits('update-budget', maxBudget, minBudget)
}
</script>

<template>
  <BaseModalV2
    :link="$t('search.eraseAll')"
    :model-value="modelValue"
    :primary-button="$t('search.displayResults', nbHits)"
    :primary-button-disabled="loadingFacets"
    data-cy="modal-filters"
    mobile-full-height
    @link="clearAll"
    @primary-button="emits('update:model-value', false)"
    @update:model-value="emits('update:model-value', $event)"
  >
    <template #content>
      <div class="mb-3 text-center font-semibold">
        {{ $t('search.filters') }} ({{ filtersCount }})
      </div>

      <div class="flex flex-col gap-6">
        <div v-if="!isNewHeader">
          <div
            v-for="(type, key) in group"
            :key="key"
            :data-testid="`search-filter-${key}`"
            class="flex items-center justify-between border-b border-gray-200 py-4"
          >
            <div class="flex">
              <BaseIcon color="text-gray-700" class="mr-3" :name="type.icon" />
              <p :id="`category-${key}`" class="mb-0">
                {{ type.category }}
              </p>
            </div>
            <div class="flex items-center">
              <BaseFormComponentsBaseInputNumber
                ref="groupFilterRefs[key]"
                v-model="type.count"
                :disabled="loadingFacets"
                :name="key"
                wrapper-class="w-full"
                @update:model-value="refineFilterNumeric(key, $event)"
              />
            </div>
          </div>
        </div>

        <template v-if="userIsAdmin || userIsPartner">
          <!-- Top filters -->
          <SearchFilterFeatured
            :facets="facets"
            :facet-filters="facetFilters"
            :loading-facets="loadingFacets"
            :title="$t('search.topFilters')"
            :top-facets="topFacetsAdmin"
            collapse
            collapse-open-default
            display-row
            type="checkboxes"
            @changeFacet="changeFacet"
            @changeExclu="changeExclu"
          />
          <!-- Area and equipments -->
          <SearchFilterAreasAndEquipments
            :title="$t('search.areasEquipments')"
            :loading-facets="loadingFacets"
            :facets="facets"
            :facet-filters="facetFilters"
            @changeFacet="changeFacet"
            @clearFacetsOther="clearFacetsOther"
          />
          <SearchFilterFacet
            v-for="filter in orderFiltersFacetAdmin"
            :key="filter.key"
            :collapse-open-default="filter.open"
            :facet="formatFacet(filter.key)"
            :loading-facets="loadingFacets"
            :title="$t(`search.facets.title${filter.key}`)"
            collapse
            display-row
            type="checkboxes"
            @changeFacet="changeFacet"
          />
        </template>
        <template v-else>
          <SearchFilterFeatured
            v-if="!isNewHeader"
            :facet-filters="facetFilters"
            :facets="facets"
            :loading-facets="loadingFacets"
            :title="$t('search.topFilters')"
            :top-facets="topFacetsClient"
            collapse
            collapse-open-default
            display-row
            type="checkboxes"
            @changeFacet="changeFacet"
          />
          <SearchFilterFacet
            v-for="filter in isNewHeader
              ? orderFiltersFacetClient.filter((_, i) => i === 0)
              : orderFiltersFacetClient"
            :key="filter.key"
            :collapse-open-default="filter.open"
            :facet="formatFacet(filter.key)"
            :loading-facets="loadingFacets"
            :title="$t(`search.facets.title${filter.key}`)"
            collapse
            display-row
            type="checkboxes"
            @changeFacet="changeFacet"
          />
          <SearchFilter
            v-if="isNewHeader"
            collapse
            collapse-open-default
            :title="$t('search.bedroomsAndBathrooms')"
          >
            <SearchFilterBedroomsAndBathrooms
              :bathrooms-quantity="bathroomsQuantity"
              :bedrooms-quantity="bedroomsQuantity"
              :loading-facets="loadingFacets"
              @refine-filter-numeric="refineFilterNumeric"
              @clear-filter-numeric="$emit('clear-filter-numeric', $event)"
            />
          </SearchFilter>
          <SearchFilter
            v-if="isNewHeader"
            collapse
            collapse-open-default
            :title="$t('search.budgetStay')"
          >
            <SearchFilterBudget
              :loading-facets="loadingFacets"
              :max-budget="maxBudget"
              :min-budget="minBudget"
              @update-budget="updateBudget"
            />
          </SearchFilter>
          <template v-if="isNewHeader">
            <SearchFilterFacet
              v-for="filter in orderFiltersFacetClient.filter(
                (_, i) => i !== 0,
              )"
              :key="filter.key"
              :collapse-open-default="filter.open"
              :facet="formatFacet(filter.key)"
              :loading-facets="loadingFacets"
              :title="$t(`search.facets.title${filter.key}`)"
              collapse
              display-row
              type="checkboxes"
              @changeFacet="changeFacet"
            />
          </template>
        </template>
      </div>
    </template>
  </BaseModalV2>
</template>

<style scoped>
.lc-loader {
  @apply w-6 h-6 inline-block relative align-middle my-8;
}
.lc-loader__spin {
  transform: rotate(0deg);
  border: 0.3rem solid #dae6e6;
  border-top: 0.3rem solid #448084;
  animation: spinBaseButton 1s linear infinite;
  @apply absolute inset-1/2 w-6 h-6 rounded-full;
}
@keyframes spinBaseButton {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
