<script setup lang="ts">
import { MAX_DEFAULT_BUDGET, MIN_DEFAULT_BUDGET } from '@/helpers/priceHelper'
import { topFacetsAdmin, topFacetsClient } from '~/helpers/search/topFacets'
import type {
  NumericFilter,
  SearchFacetObject,
  SearchFacetTuple,
} from '~/types/search/types'

const { locale } = useI18n()
const { filtersCount } = useSearchFiltersCount()
const { userIsAdmin, userIsPartner } = useAuth()
const { isNewHeader } = useHeaderV2()

const props = withDefaults(
  defineProps<{
    bathrooms?: number
    bedrooms?: number
    loadingFacets?: boolean
    facetFilters?: Record<string, string[]>
    facets?: Record<string, Record<string, number>>
    maxBudget?: string
    minBudget?: string
  }>(),
  {
    bathrooms: 1,
    bedrooms: 1,
    loadingFacets: false,
    facetFilters: () => ({}),
    facets: () => ({}),
    maxBudget: MAX_DEFAULT_BUDGET,
    minBudget: MIN_DEFAULT_BUDGET,
  },
)

const emits = defineEmits<{
  'change-exclu': [boolean]
  'change-facet': SearchFacetTuple
  'clear-facets-other': []
  'clear-filter-numeric': [NumericFilter]
  'refine-filter-numeric': [NumericFilter, number]
  'reset-filters': []
  'update-budget': [string, string]
}>()

const orderFiltersFacetClient = [
  { key: 5, open: true }, // In the spotlight
  { key: 10, open: true }, // Swimming pool
  { key: 1, open: true }, // Property type
  { key: 2, open: false }, // Environment
  { key: 7, open: false }, // Well-being & Sport
  { key: 4, open: false }, // Amenities
  { key: 3, open: false }, // Property features
]

const orderFiltersFacetAdmin = [
  { key: 1, open: true }, // Property type
  { key: 11, open: true }, // Swimming pool
  { key: 8, open: true }, // Conciergerie
  { key: 9, open: true }, // Licence
  { key: 5, open: true }, // In the spotlight
  { key: 2, open: false }, // Environment
  { key: 7, open: false }, // Well-being & Sport
  { key: 4, open: false }, // Amenities
  { key: 3, open: false }, // Property features
]

const changeFacet = (...args: SearchFacetTuple) => {
  emits('change-facet', ...args)
}
const clearFacetsOther = () => {
  emits('clear-facets-other')
}

const formatFacet = (key: number) => {
  const tag = `searchable_tags.${key}.${locale.value}`

  if (props.facets[tag]) {
    const values = Object.keys(props.facets[tag]).map((value) => {
      const facetQuery = `${tag}: ${value}`

      return {
        value,
        checked: props.facetFilters[key]?.includes(facetQuery) || false,
      }
    })

    return {
      key,
      tag,
      values,
    } as SearchFacetObject
  }
}

const changeExclu = (isExclu: boolean) => {
  emits('change-exclu', isExclu)
}

const refineFilterNumeric = (attribute: NumericFilter, value: number) => {
  emits('refine-filter-numeric', attribute, value)
}

const updateBudget = (maxBudget: string, minBudget: string) => {
  emits('update-budget', maxBudget, minBudget)
}
</script>

<template>
  <div
    :class="[
      'flex flex-col gap-6 overflow-y-auto rounded bg-white',
      { 'max-h-[calc(100vh-210px-2rem)]': userIsAdmin || userIsPartner },
      {
        'max-h-[calc(100vh-230px-2rem)] xl:max-h-[calc(100vh-170px-2rem)]':
          !userIsAdmin && !userIsPartner && !isNewHeader,
      },
      { 'border border-gray-200 p-4': !isNewHeader },
      { 'max-h-[calc(100vh-130px-2rem)]': isNewHeader },
    ]"
  >
    <template v-if="userIsAdmin || userIsPartner">
      <!-- Top filters -->
      <SearchFilterFeatured
        :facets="facets"
        :facet-filters="facetFilters"
        :loading-facets="loadingFacets"
        :title="$t('search.topFilters')"
        :top-facets="topFacetsAdmin"
        collapse
        collapse-open-default
        display-row
        type="checkboxes"
        @changeFacet="changeFacet"
        @changeExclu="changeExclu"
      />
      <!-- Area and equipments -->
      <SearchFilterAreasAndEquipments
        :title="$t('search.areasEquipments')"
        :loading-facets="loadingFacets"
        :facets="facets"
        :facet-filters="facetFilters"
        @changeFacet="changeFacet"
        @clearFacetsOther="clearFacetsOther"
      />
      <!-- All facets -->
      <SearchFilterFacet
        v-for="filter in orderFiltersFacetAdmin"
        :key="filter.key"
        :collapse-open-default="filter.open"
        :facet="formatFacet(filter.key)"
        :loading-facets="loadingFacets"
        :title="$t(`search.facets.title${filter.key}`)"
        collapse
        display-row
        type="checkboxes"
        @changeFacet="changeFacet"
      />
    </template>
    <template v-else>
      <template v-if="isNewHeader">
        <div class="text-left">
          <button
            type="button"
            :class="[
              'flex items-center text-xs font-semibold',
              {
                'text-gray-700': filtersCount,
                'cursor-not-allowed text-gray-400': !filtersCount,
              },
            ]"
            :disabled="!filtersCount"
            @click="$emit('reset-filters')"
          >
            <span class="underline">{{ $t('search.resetFilters') }}</span>
            <span
              class="ml-2 flex size-5 items-center justify-center rounded-full bg-gray-100 text-3xs"
            >
              {{ filtersCount }}
            </span>
          </button>
        </div>
      </template>

      <SearchFilterFeatured
        v-if="!isNewHeader"
        :facet-filters="facetFilters"
        :facets="facets"
        :loading-facets="loadingFacets"
        :title="$t('search.topFilters')"
        :top-facets="topFacetsClient"
        collapse
        collapse-open-default
        display-row
        type="checkboxes"
        @changeFacet="changeFacet"
      />
      <SearchFilterFacet
        v-for="filter in isNewHeader
          ? orderFiltersFacetClient.filter((_, i) => i === 0)
          : orderFiltersFacetClient"
        :key="filter.key"
        :collapse-open-default="filter.open"
        :facet="formatFacet(filter.key)"
        :loading-facets="loadingFacets"
        :title="$t(`search.facets.title${filter.key}`)"
        collapse
        display-row
        type="checkboxes"
        @changeFacet="changeFacet"
      />
      <SearchFilter
        v-if="isNewHeader"
        collapse
        collapse-open-default
        :title="$t('search.bedroomsAndBathrooms')"
      >
        <SearchFilterBedroomsAndBathrooms
          :bathrooms-quantity="bathrooms"
          :bedrooms-quantity="bedrooms"
          :loading-facets="loadingFacets"
          @refine-filter-numeric="refineFilterNumeric"
          @clear-filter-numeric="$emit('clear-filter-numeric', $event)"
        />
      </SearchFilter>
      <SearchFilter
        v-if="isNewHeader"
        collapse
        collapse-open-default
        :title="$t('search.budgetStay')"
      >
        <SearchFilterBudget
          :loading-facets="loadingFacets"
          :max-budget="maxBudget"
          :min-budget="minBudget"
          @update-budget="updateBudget"
        />
      </SearchFilter>
      <template v-if="isNewHeader">
        <SearchFilterFacet
          v-for="filter in orderFiltersFacetClient.filter((_, i) => i !== 0)"
          :key="filter.key"
          :collapse-open-default="filter.open"
          :facet="formatFacet(filter.key)"
          :loading-facets="loadingFacets"
          :title="$t(`search.facets.title${filter.key}`)"
          collapse
          display-row
          type="checkboxes"
          @changeFacet="changeFacet"
        />
      </template>
    </template>
  </div>
</template>
