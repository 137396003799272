<script setup lang="ts">
import {
  MapboxImage,
  MapboxLayer,
  MapboxMap,
  MapboxNavigationControl,
  MapboxPopup,
  MapboxSource,
} from '@studiometa/vue-mapbox-gl'
import debounce from 'lodash.debounce'

import 'mapbox-gl/dist/mapbox-gl.css'

import type {
  LngLatBounds,
  GeoJSONSource,
  Map as MapInstance,
  MapMouseEvent,
} from 'mapbox-gl'
import type { HousesCoordinates } from 'lc-services/types'

const {
  hitsMaps = [],
  coordinatesBoundaries = [],
  isLoading = false,
  geographicAreaActive = false,
} = defineProps<{
  hitsMaps: HousesCoordinates['features']
  isLoading?: boolean
  coordinatesBoundaries: [number, number][]
  geographicAreaActive?: boolean
}>()
const emits = defineEmits<{
  'track-house': [houseId: number]
  'map-moved': [bounds: number[]]
}>()

const config = useRuntimeConfig()
const { isMobile } = useBreakpoint()

const MOVE_THRESHOLD_KM = 5

const map = ref<MapInstance>()
const isOpen = ref(false)
const position = ref<[number, number]>([0, 0])
const content = ref<HousesCoordinates['features'][0]['properties']>()
const featureId = ref<number>()
const lastBounds = ref<LngLatBounds | null>(null)

const data = computed(() => ({
  type: 'FeatureCollection',
  features: hitsMaps.map((hit) => ({ ...hit, id: hit.properties.houseId })),
}))

const onCreated = (mapInstance: MapInstance) => {
  map.value = mapInstance
  lastBounds.value = map.value.getBounds()
}

// clusteredPoint
const clustersMouseenterHandler = () => {
  if (map.value) map.value.getCanvas().style.cursor = 'pointer'
}

const clustersMouseleaveHandler = () => {
  if (map.value) map.value.getCanvas().style.cursor = ''
}

const clustersClickHandler = (event: MapMouseEvent) => {
  const feature = map.value?.queryRenderedFeatures(event.point, {
    layers: ['clusters'],
  })[0]
  const clusterId = feature?.properties?.cluster_id

  // Return before move map if event is defaultPrevented
  if (event.defaultPrevented) return

  map.value
    ?.getSource<GeoJSONSource>('points')
    ?.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return

      map.value?.easeTo({
        center: feature?.geometry?.coordinates,
        zoom: zoom || undefined,
      })
    })
}

// unclusteredPoint
const unclusteredPointMouseenterHandler = (event: MapMouseEvent) => {
  const feature = event.features?.[0]
  if (!map.value || !feature) return

  map.value.getCanvas().style.cursor = 'pointer'
  featureId.value = Number(feature.id)
  map.value.setFeatureState(
    { source: 'points', id: featureId.value },
    { hover: true },
  )
}

const unclusteredPointMouseleaveHandler = () => {
  if (!map.value || !featureId.value) return

  map.value.getCanvas().style.cursor = ''
  map.value.setFeatureState(
    { source: 'points', id: featureId.value },
    { hover: false },
  )
  featureId.value = undefined
}

const unclusteredPointClickHandler = (event: MapMouseEvent) => {
  const feature = event.features?.[0]
  if (feature) openPopup(feature)
}

const openPopup = async ({
  geometry,
  properties,
}: {
  geometry: HousesCoordinates['features'][0]['geometry']
  properties: HousesCoordinates['features'][0]['properties']
}) => {
  // Wait for mapbox to send the correct informations to the popup
  await nextTick()

  position.value = [geometry.coordinates[0], geometry.coordinates[1]]
  isOpen.value = true
  map.value?.easeTo({
    center: position.value,
    offset: [0, 100],
    duration: 1000,
  })

  /**
   * Mapbox GL convert's properties values to JSON, so we need to parse them
   * to retreive any complex data structure such as arrays and objects.
   */
  content.value = Object.fromEntries(
    Object.entries(properties).map(([key, value]) => {
      try {
        return [key, JSON.parse(value)]
      } catch {
        // Silence is golden.
      }

      return [key, value]
    }),
  )
}

const getDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  const R = 6371
  const rad = Math.PI / 180
  const dLat = (lat2 - lat1) * rad
  const dLon = (lon2 - lon1) * rad

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * rad) *
      Math.cos(lat2 * rad) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

const checkBoundsChange = debounce(() => {
  if (!map.value) return

  const newBounds = map.value.getBounds()
  if (!newBounds || !lastBounds.value) return

  const lastCenter = lastBounds.value.getCenter()
  const newCenter = newBounds.getCenter()

  const distanceMoved = getDistance(
    lastCenter.lat,
    lastCenter.lng,
    newCenter.lat,
    newCenter.lng,
  )

  if (distanceMoved > MOVE_THRESHOLD_KM) {
    lastBounds.value = newBounds

    const insideBoundingBox = [
      newBounds.getNorthEast().lat,
      newBounds.getNorthEast().lng,
      newBounds.getSouthWest().lat,
      newBounds.getSouthWest().lng,
    ]

    emits('map-moved', insideBoundingBox)
  }
}, 500)

watch(
  () => coordinatesBoundaries,
  () => {
    if (geographicAreaActive) return
    const min = coordinatesBoundaries?.[0]
    const max = coordinatesBoundaries?.[1]
    if (!min.length || !max.length) return

    map.value?.fitBounds([min, max], { duration: 1000 })
  },
)
</script>

<template>
  <div class="relative">
    <div
      v-if="isLoading"
      class="absolute top-3 z-10 flex w-full items-center justify-center"
    >
      <div class="flex items-center rounded bg-white px-4 py-2">
        <div
          class="inline-block size-6 animate-spin rounded-full border-[3px] border-gray-700 border-t-transparent"
          role="status"
          aria-label="loading"
        />
      </div>
    </div>
    <MapboxMap
      class="h-full"
      map-style="mapbox://styles/mapbox/streets-v12"
      :access-token="config.public.mapboxApiKey"
      :bounds="coordinatesBoundaries"
      :fit-bounds-options="{
        padding: isMobile ? 40 : 80,
        maxZoom: 12,
      }"
      :min-zoom="3"
      @mb-created="onCreated"
      @mb-dragend="checkBoundsChange"
      @mb-zoomend="
        (event: MapMouseEvent) => {
          if (event.originalEvent) checkBoundsChange()
        }
      "
    >
      <MapboxImage id="house" src="/mapbox/house.png">
        <MapboxSource
          id="points"
          :options="{
            type: 'geojson',
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50,
            data: data,
          }"
        />

        <MapboxLayer
          id="clusters"
          :options="{
            type: 'circle',
            source: 'points',
            filter: ['has', 'point_count'],
            paint: {
              'circle-color': '#202020',
              'circle-stroke-color': '#202020',
              'circle-stroke-width': 3,
              'circle-stroke-opacity': 0.3,
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                15,
                100,
                20,
                750,
                25,
              ],
            },
          }"
          @mb-click="clustersClickHandler"
          @mb-mouseenter="clustersMouseenterHandler"
          @mb-mouseleave="clustersMouseleaveHandler"
        />

        <MapboxLayer
          id="cluster-count"
          :options="{
            type: 'symbol',
            source: 'points',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': '{point_count}',
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
              'text-size': 12,
            },
            paint: {
              'text-color': '#FFFFFF',
            },
          }"
        />

        <MapboxLayer
          id="hover-circle"
          :options="{
            type: 'circle',
            source: 'points',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-radius': 20,
              'circle-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                '#1C5050',
                '#202020',
              ],
              'circle-opacity': 1,
            },
          }"
        />

        <MapboxLayer
          id="unclustered-points"
          :options="{
            type: 'symbol',
            source: 'points',
            filter: ['!', ['has', 'point_count']],
            layout: {
              'icon-image': 'house',
              'icon-size': 0.33,
              'icon-allow-overlap': true,
            },
          }"
          @mb-click="unclusteredPointClickHandler"
          @mb-mouseenter="unclusteredPointMouseenterHandler"
          @mb-mouseleave="unclusteredPointMouseleaveHandler"
        />
      </MapboxImage>

      <MapboxPopup
        v-if="isOpen"
        :key="position.join('-')"
        :close-button="false"
        :lng-lat="position"
        :offset="[0, -30]"
        anchor="bottom"
        class-name="search-popup-mapbox"
        max-width="260"
        @mb-close="() => (isOpen = false)"
      >
        <SearchMapPopupContent
          v-if="content"
          :hit-map="content"
          @close="isOpen = false"
          @track-house="$emit('track-house', $event)"
        />
      </MapboxPopup>
      <MapboxNavigationControl position="bottom-right" :show-compass="false" />
    </MapboxMap>
  </div>
</template>

<style>
.search-popup-mapbox .mapboxgl-popup-content {
  @apply p-0 rounded;
}

.search-popup-mapbox .mapboxgl-popup-tip {
  @apply hidden;
}
</style>
