import { buildFiltersAppliedSearchTracking } from '~/helpers/search/searchTracking'
import type { HouseSlug, Locale } from 'lc-services/types'

export const useSearchTracking = () => {
  const { trackEvent, pageType } = useTracking()
  const { locale } = useI18n<unknown, Locale>()

  const { bathrooms, bedrooms, capacity } = useSearchNumericFilters()
  const { facetsFiltersParams } = useSearchFacetFilters()
  const { startDate, endDate } = useSearchDateFilters()
  const { minBudget, maxBudget } = useSearchBudgetFilters()
  const { queryValue } = useSearchLocationFilters()
  const { nbHits } = useSearchResults({ startDate, endDate })

  const filtersAppliedPayload = (
    searchableTagsIdentifiers: Record<string, HouseSlug>[],
  ) => {
    return buildFiltersAppliedSearchTracking({
      capacity: capacity.value,
      bedrooms: bedrooms.value,
      bathrooms: bathrooms.value,
      destinationId: queryValue.value?.value?.toString() || undefined,
      endDate: endDate.value,
      facetsFiltersParams: facetsFiltersParams.value,
      maxBudget: maxBudget.value,
      minBudget: minBudget.value,
      startDate: startDate.value,
      locale: locale.value,
      searchableTagsIdentifiers: searchableTagsIdentifiers || [],
    })
  }

  const getFiltersAppliedName = (
    searchableTagsIdentifiers: Record<string, { en: string; fr: string }>[],
  ) => {
    const filtersApplied = filtersAppliedPayload(searchableTagsIdentifiers)

    return Object.entries(filtersApplied).flatMap(([key, value]) => {
      if (key === 'dates_selected' && !value) return []
      return Array.isArray(value)
        ? value.map((arrayValue) => `${arrayValue}_facet`)
        : [key]
    })
  }

  const trackSearchHouse = ({
    houseId,
    mapVisible,
    searchableTagsIdentifiers = [],
  }: {
    houseId: number
    mapVisible: boolean
    searchableTagsIdentifiers: Record<string, HouseSlug>[]
  }) => {
    const filtersApplied = filtersAppliedPayload(searchableTagsIdentifiers)
    const tracking = {
      category: 'Search',
      event: 'click_on_card',
      house_id: houseId,
      is_map_view: mapVisible,
      filters_applied: filtersApplied,
    }

    trackEvent(tracking)
  }

  const trackSearchResultsViewed = ({
    isCorrectHousePeriod,
    mapVisible,
    searchableTagsIdentifiers = [],
  }: {
    isCorrectHousePeriod?: boolean
    mapVisible: boolean
    searchableTagsIdentifiers: Record<string, HouseSlug>[]
  }) => {
    const filtersApplied = filtersAppliedPayload(searchableTagsIdentifiers)
    const tracking = {
      category: 'Search',
      event: 'search_results_viewed',
      number_of_results: nbHits.value,
      result_view: mapVisible ? 'map' : 'list',
      filters_applied: filtersApplied,
      ...(typeof isCorrectHousePeriod === 'boolean'
        ? { is_correct_house_period: isCorrectHousePeriod }
        : null),
    }

    trackEvent(tracking)
  }

  const trackSearchMap = ({ event }: { event: 'map_close' | 'map_open' }) => {
    const { query } = useRoute()
    const searchQuery = decodeURIComponent(
      new URLSearchParams(Object(query)).toString(),
    )

    trackEvent({
      category: 'Search',
      event,
      search_query: searchQuery,
      ...(event === 'map_open'
        ? {
            source: 'Search CTA',
            page_type: pageType.value,
          }
        : {}),
    })
  }

  const trackFavoritePeriod = ({ id, name }: { id: string; name: string }) => {
    trackEvent({
      category: 'Search',
      event: 'period_update_click',
      destination_id: id,
      destination_name: name,
    })
  }

  const trackSidebar = ({
    searchableTagsIdentifiers,
    show,
  }: {
    searchableTagsIdentifiers: Record<string, { en: string; fr: string }>[]
    show: boolean
  }) => {
    const filtersAppliedName = getFiltersAppliedName(searchableTagsIdentifiers)

    trackEvent({
      event: show ? 'search_filters_show_click' : 'search_filters_hide_click',
      filters_applied_name: filtersAppliedName,
    })
  }

  const trackResetFilter = () => {
    trackEvent({ event: 'search_filters_reset_click' })
  }

  return {
    trackFavoritePeriod,
    trackResetFilter,
    trackSearchHouse,
    trackSearchMap,
    trackSearchResultsViewed,
    trackSidebar,
  }
}
