<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    collapse?: boolean
    collapseOpenDefault?: boolean
    title?: string
  }>(),
  {
    collapse: false,
    collapseOpenDefault: false,
    title: '',
  },
)
defineSlots<{ default(): any }>()

const open = ref(props.collapseOpenDefault)
</script>

<template>
  <div>
    <template v-if="title">
      <button
        v-if="collapse"
        :class="['flex w-full justify-between', { 'mb-3': open }]"
        type="button"
        @click="open = !open"
      >
        <span class="text-md font-semibold">{{ title }}</span>
        <BaseIcon :name="open ? 'minus' : 'plus'" :size="1.5" />
      </button>
      <div v-else class="mb-3 font-semibold">{{ title }}</div>
    </template>

    <div v-if="collapse">
      <div v-show="open">
        <slot />
      </div>
    </div>
    <slot v-else />
  </div>
</template>
