<script setup lang="ts">
const { t } = useI18n({ useScope: 'local' })
</script>

<template>
  <div class="flex flex-col justify-center gap-4">
    <BaseIcon name="architectHouse" :size="4" />
    <h2 class="m-0 text-3xl">
      {{ t('title') }}
    </h2>
    <p class="m-0">
      {{ t('text') }}
    </p>

    <TheMeetingSchedulerButton
      class="bg-gray-700"
      color="white"
      source="search page"
    >
      <span>{{ $t('search.scheduleACall') }}</span>
    </TheMeetingSchedulerButton>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Can't find the house of your dreams?",
    "text": "Our advisors are available to offer you a selection of bespoke houses."
  },
  "fr": {
    "title": "Vous ne trouvez pas la maison de vos rêves ?",
    "text": "Nos conseillers sont disponibles pour vous proposer une sélection de maisons sur-mesure."
  }
}
</i18n>
