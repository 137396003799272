import type { QueryModelType } from '~/types/search/types'

export const useSearchMap = ({
  endDate,
  mapVisible,
  searchContainer,
  startDate,
}: {
  endDate: ComputedRef<string | null>
  mapVisible: Ref<boolean>
  searchContainer: Ref<HTMLElement | null>
  startDate: ComputedRef<string | null>
}) => {
  const { currency } = useUser()
  const { userIsAdmin, userIsPartner } = useAuth()
  const { queryValue, queryValueMultiple } = useSearchLocationFilters()
  const { fetchHouseMapResults } = useSearchResults({ startDate, endDate })

  const showMapButton = ref(true)
  const geographicAreaActive = ref(false)

  const handleShowMapButton = () => {
    let condition

    if (searchContainer.value) {
      const searchContainerHeight =
        searchContainer.value.getBoundingClientRect().height
      if (mapVisible.value) {
        // Add offset if map is visible
        condition =
          window.scrollY + window.innerHeight > searchContainerHeight + 200
      } else {
        condition = window.scrollY + window.innerHeight > searchContainerHeight
      }

      if (condition) {
        showMapButton.value = false
      } else {
        showMapButton.value = true
      }
    }
  }

  const onMapMoved = async (
    bounds: number[],
    searchHousesPayload: QueryModelType,
  ) => {
    if (
      (userIsAdmin.value || userIsPartner.value) &&
      !queryValueMultiple.value.length
    )
      return
    else if (!queryValue.value?.value) return

    geographicAreaActive.value = true
    await fetchHouseMapResults({
      currency,
      searchHousesPayload: {
        ...searchHousesPayload,
        insideBoundingBox: [bounds],
        destinationIds: [],
      },
    })
  }

  onBeforeMount(() => {
    document.addEventListener('scroll', handleShowMapButton)
  })

  onUnmounted(() => {
    document.removeEventListener('scroll', handleShowMapButton)
  })

  return {
    geographicAreaActive,
    onMapMoved,
    showMapButton,
  }
}
