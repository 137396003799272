<script setup lang="ts">
import { $dayjs } from '@/utils/dayjs'

import { useButtons } from '~/components/BaseForm/compose/use-buttons'
import formWaitingList from '~/forms/search/waitingList/groupFields'
import type { SendMutationType } from '~/helpers/BaseForm/sendMutation'
import type { MutationType } from '../BaseForm/types'
import type {
  SupportedCurrencies,
  WaitingSearchPayload,
} from 'lc-repository/entities'

const {
  bathrooms = null,
  bedrooms = null,
  capacity = null,
  currency = '',
  destinationId,
  destinationName,
  endDate = $dayjs().format('YYYY-MM-DD'),
  maxBudget = '',
  minBudget = '',
  startDate = $dayjs().format('YYYY-MM-DD'),
} = defineProps<{
  bathrooms?: number | null
  bedrooms?: number | null
  capacity?: number | null
  currency?: SupportedCurrencies
  destinationId: number
  destinationName: string
  endDate?: string | null
  maxBudget?: string
  minBudget?: string
  startDate?: string | null
}>()

const { $lcRepositories } = useNuxtApp()
const { t } = useI18n({ useScope: 'local' })
const { trackEvent } = useTracking()
const { user } = useUser()
const localePath = useLocalePath()

const { btnPrimary } = useButtons()
btnPrimary.text = computed(() => t('subscribe'))
if (btnPrimary.attrs) {
  btnPrimary.attrs.class = 'base-form-button mt-0'
  btnPrimary.attrs.color = 'secondary'
}

const groupFieldsFormWaitingList = reactive(formWaitingList())
const step = ref(0)

const initialData = reactive({ email: user.value.email })
const configForm = reactive({
  fields: {
    email: {
      attr: {
        disabled: Boolean(user.value.email),
        placeholder: computed(() => t('placeholderEmail')),
      },
    },
  },
})

const destinationNameFormatted = computed(() => destinationName.split(',')[0])

watch(
  () => destinationId,
  () => {
    step.value = 0
  },
)

const constructParamsWaitingList = (formData: {
  email: string
}): WaitingSearchPayload => {
  const checkInDate = $dayjs(startDate).format('YYYY-MM-DD')
  const checkOutDate = $dayjs(endDate).format('YYYY-MM-DD')
  const equal = checkInDate === checkOutDate

  const params = {
    bedrooms: bedrooms,
    bathrooms: bathrooms,
    min_budget: minBudget,
    max_budget: maxBudget,
    check_in_date: equal ? null : checkInDate,
    check_out_date: equal ? null : checkOutDate,
    currency: minBudget || maxBudget ? currency : '',
    people: capacity,
  }

  const formatParams = Object.keys(params)
    .filter((key) => params[key as keyof typeof params])
    .reduce(
      (acc, curr) => ({ ...acc, [curr]: params[curr as keyof typeof params] }),
      {},
    ) as WaitingSearchPayload

  return {
    ...formatParams,
    destination_id: destinationId,
    email: formData.email,
  }
}

const submitWaitingList = (data: WaitingSearchPayload) =>
  $lcRepositories.waitingSearches
    .addWaitingSearch({ payload: data })
    .then((res) => {
      if (res.errors) throw res.errors
    })

const mutationWaitingList: MutationType<{ email: string }> = (data) => ({
  request: (input) =>
    submitWaitingList({ ...constructParamsWaitingList(input) }),
  input: data,
})

const onSubmitWaitingList: () => SendMutationType['onSubmit'] = () => ({
  success: () => {
    step.value = 1
    trackEvent({
      event: 'destination_alert_subscription',
      destination_alert: destinationName,
    })
  },
  error: (err: any) => {
    if (err.errors.response?.data && err.errors.response?.status === 400) {
      if (
        err.errors.response.data?.errors?.[0]?.waiting_search
          ?.destination_id?.[0]?.error === 'taken'
      ) {
        step.value = 1
      }
    }
  },
})

const submitNewsletter = async () => {
  const { data } = await $lcRepositories.newsletterEmails.subscribeNewsletter({
    email: initialData.email,
  })

  if (!data) return

  step.value = 2
  trackEvent({
    event: 'newsletter_subscription',
    label: 'search',
    post_alert: true,
  })
}
</script>

<template>
  <div class="flex flex-col md:flex-row">
    <div class="w-full flex-none md:w-[440px]">
      <BaseNuxtImg
        :height="318"
        :width="480"
        alt="alert desti"
        src="production/assets/images/search/alert-desti-cover.jpg"
      />
    </div>
    <div
      class="flex w-full flex-col items-center justify-center bg-beige px-4 py-10 text-center md:p-10 lg:min-h-[358px] desktop:min-h-[318px]"
    >
      <template v-if="step === 0">
        <div class="font-monospace mb-2 text-3xl leading-8">
          {{ t('step0.title') }}
        </div>
        <div class="mb-6 text-md leading-5 md:max-w-[330px]">
          {{ t('step0.subtitle') }}
        </div>

        <BaseForm
          :active-modal-errors="false"
          :active-modal-save-or-quit="false"
          :button-primary="btnPrimary"
          :config-form="configForm"
          :group-fields="groupFieldsFormWaitingList"
          :initial-data="initialData"
          :mutation="mutationWaitingList"
          :on-submit="onSubmitWaitingList"
          class="waiting-list-form w-full"
          container-button-class="mt-3 flex-wrap container-button-class"
          id-form="waiting-list-form"
          wrapper-button-class="justify-center"
        />

        <I18nT
          class="mb-0 mt-3 max-w-[335px] text-xs text-gray-600"
          keypath="terms[0]"
          tag="p"
        >
          <template #link>
            <a
              :href="localePath('legal-privacyPolicy')"
              target="_blank"
              class="text-gray-600 underline"
            >
              {{ t('terms[1]') }}
            </a>
          </template>
        </I18nT>
      </template>
      <template v-else-if="step === 1">
        <div class="font-monospace mb-2 text-3xl leading-8 md:max-w-[456px]">
          {{ t('step1.title', { destinationName: destinationNameFormatted }) }}
        </div>
        <div class="mb-6 text-md leading-5 md:max-w-[456px]">
          {{ t('step1.subtitle') }}
        </div>

        <BaseButton color="secondary" @click="submitNewsletter">
          {{ t('step1.cta') }}
        </BaseButton>

        <I18nT
          class="mb-0 mt-3 max-w-[335px] text-xs text-gray-600"
          keypath="terms[0]"
          tag="p"
        >
          <template #link>
            <a
              :href="localePath('legal-privacyPolicy')"
              target="_blank"
              class="text-gray-600 underline"
            >
              {{ t('terms[1]') }}
            </a>
          </template>
        </I18nT>
      </template>
      <template v-else-if="step === 2">
        <div class="font-monospace mb-2 text-3xl leading-8">
          {{ t('step2.title') }}
        </div>
        <div class="mb-6 text-md leading-5">
          {{ t('step2.subtitle') }}
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
@screen lg {
  :deep(.waiting-list-form) {
    @apply flex w-full;
  }
  :deep(.waiting-list-form > div:first-child) {
    @apply w-full;
  }
  :deep(.base-input),
  :deep(.base-input input) {
    @apply rounded;
  }
  :deep(.container-button-class) {
    @apply mt-0 flex-none;
  }
  :deep(.base-form-button) {
    @apply h-[51px] text-md -ml-2;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "placeholderEmail": "Enter your email address",
    "subscribe": "Subscribe",
    "step0": {
      "title": "Are you interested in this destination?",
      "subtitle": "Be the first to know when a home is added to our Collection."
    },
    "step1": {
      "title": "Your subscription for destination {destinationName} is now validated!",
      "subtitle": "Subscribe to our newsletter and receive personalized content about the world's most beautiful homes.",
      "cta": "Subscribe to newsletter"
    },
    "step2": {
      "title": "Your subscription is validated!",
      "subtitle": "You'll soon receive travel ideas and much more."
    },
    "terms": [
      "By subscribing, you declare that you have read and accepted and accepted our {link}",
      "Privacy Policy."
    ]
  },
  "fr": {
    "placeholderEmail": "Renseignez votre adresse e-mail",
    "subscribe": "Je m'abonne",
    "step0": {
      "title": "Cette destination vous intéresse\u202F?",
      "subtitle": "Soyez les premiers informés lorsqu’une maison est ajoutée à notre Collection."
    },
    "step1": {
      "title": "Votre inscription pour la destination {destinationName} est validée !",
      "subtitle": "Inscrivez-vous à notre newsletter et recevez des contenus personnalisés sur les plus belles maisons du monde.",
      "cta": "Recevoir la newsletter"
    },
    "step2": {
      "title": "Votre inscription est validée\u202F!",
      "subtitle": "Vous recevrez prochainement des idées de voyages et bien plus encore."
    },
    "terms": [
      "En vous abonnant, vous déclarez avoir pris connaissance et accepté notre {link}",
      "Politique de confidentialité."
    ]
  }
}
</i18n>
