import type { Cookie } from 'universal-cookie/es6/types'
import type { LocationQuery } from 'vue-router'

export const createDates = ({
  $httpCookies,
  query,
}: {
  $httpCookies: Cookie
  query: LocationQuery
}) => {
  const cookies = $httpCookies?.get('search_params')
  const hasDateCookie = ref(!!(cookies?.from_date && cookies?.to_date))

  const startDate = ref<string | null>(null)
  const endDate = ref<string | null>(null)

  if (isNonEmptyString(query.startDate) && isNonEmptyString(query.endDate)) {
    startDate.value = query.startDate
    endDate.value = query.endDate

    $httpCookies.set(
      'search_params',
      {
        from_date: startDate.value,
        to_date: endDate.value,
      },
      { maxAge: 3600 * 24 },
    )
  } else if (cookies?.from_date && cookies?.to_date) {
    startDate.value = cookies.from_date
    endDate.value = cookies.to_date
  }

  return {
    endDate,
    hasDateCookie,
    startDate,
  }
}
