<script setup lang="ts">
import debounce from 'lodash.debounce'

import type { NumericFilter } from '@/types/search/types'

const { t } = useI18n()

const {
  bathroomsQuantity = 0,
  bedroomsQuantity = 0,
  loadingFacets = false,
} = defineProps<{
  bathroomsQuantity?: number
  bedroomsQuantity?: number
  loadingFacets?: boolean
}>()

const emits = defineEmits<{
  'clear-filter-numeric': [NumericFilter]
  'refine-filter-numeric': [NumericFilter, number]
}>()

const group = reactive<
  Record<'bathrooms' | 'bedrooms', { category: string; count: number }>
>({
  bedrooms: {
    category: t('search.bedroomsKey', 2),
    count: bedroomsQuantity,
  },
  bathrooms: {
    category: t('search.bathrooms_mobile'),
    count: bathroomsQuantity,
  },
})

const refineFilterNumeric = (attribute: NumericFilter, value: number) => {
  if (value === 0) {
    emits('clear-filter-numeric', attribute)
  } else {
    emits('refine-filter-numeric', attribute, value)
  }
}

const handleDebounce = debounce((attribute: NumericFilter, value: number) => {
  refineFilterNumeric(attribute, value)
}, 1000)

watch(
  [() => bathroomsQuantity, () => bedroomsQuantity],
  ([newBathrooms, newBedrooms]) => {
    group.bathrooms.count = newBathrooms
    group.bedrooms.count = newBedrooms
  },
)
</script>

<template>
  <div class="mt-2 md:mr-1">
    <div
      v-for="(type, key) in group"
      :key="key"
      :data-testid="`search-filter-${key}`"
      class="flex items-center justify-between py-2"
    >
      <p class="mb-0 text-md">
        {{ type.category }}
      </p>
      <div class="flex items-center">
        <BaseFormComponentsBaseInputNumber
          v-model="type.count"
          :disabled="loadingFacets"
          :name="key"
          placeholder="0"
          wrapper-class="w-full override-style-input"
          @update:model-value="
            (value, uptatedByInput) =>
              uptatedByInput
                ? refineFilterNumeric(key, value)
                : handleDebounce(key, value)
          "
        />
      </div>
    </div>
  </div>
</template>
