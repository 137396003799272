<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const emits = defineEmits<{
  click: [`searchable_tags.2.${Locale}`, string]
}>()
const { isMobile } = useBreakpoint()
const { locale, t } = useI18n<unknown, Locale>()
const { trackEvent } = useTracking()

const route = useRoute()

const tagName = computed(() => t('search.hotdeals.filterLabel'))
const tag = `searchable_tags.2.${locale.value}` as const
const searchQuery = computed(() => `${tag}: ${tagName.value}`)
const isSearchingHotDeals = computed(() =>
  route.query.facetFilter?.includes(searchQuery.value),
)

const onClickHotDeals = () => {
  trackEvent({
    event: 'search_favourites_click',
    search_query: searchQuery.value,
  })
  emits('click', tag, tagName.value)
}
</script>

<template>
  <BaseRow
    v-if="!isSearchingHotDeals"
    class="flex flex-nowrap items-center gap-8 bg-primary-100 px-3 py-4 text-xs sm:mx-0.5 sm:mb-4 sm:px-4 sm:text-md md:gap-4"
  >
    <div class="flex items-center gap-2">
      <BaseIcon v-if="!isMobile" name="calendar" />
      <p class="m-0">
        {{ $t('search.hotdeals.text') }}
      </p>
    </div>

    <button
      class="flex-none py-3 text-xs font-bold text-gray-700 underline md:py-0 md:text-md"
      type="button"
      @click.prevent="onClickHotDeals"
    >
      {{ $t('search.hotdeals.link') }}
    </button>
  </BaseRow>
</template>
