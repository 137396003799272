<script setup lang="ts">
import debounce from 'lodash.debounce'
import { MAX_DEFAULT_BUDGET, MIN_DEFAULT_BUDGET } from '@/helpers/priceHelper'

import type { BaseFormComponentsBaseInput } from '#build/components'

const emits = defineEmits<{
  'update-budget': [string, string]
}>()

const {
  loadingFacets = false,
  maxBudget = MAX_DEFAULT_BUDGET,
  minBudget = MIN_DEFAULT_BUDGET,
} = defineProps<{
  loadingFacets?: boolean
  maxBudget?: string
  minBudget?: string
}>()

const budgetMinRef = ref<InstanceType<
  typeof BaseFormComponentsBaseInput
> | null>(null)
const budgetMaxRef = ref<InstanceType<
  typeof BaseFormComponentsBaseInput
> | null>(null)

const maxBudgetModel = ref(maxBudget === MAX_DEFAULT_BUDGET ? '' : maxBudget)
const minBudgetModel = ref(minBudget === MIN_DEFAULT_BUDGET ? '' : minBudget)

const updateBudget = () => {
  const max =
    maxBudgetModel.value === '' ? MAX_DEFAULT_BUDGET : maxBudgetModel.value
  const min =
    minBudgetModel.value === '' ? MIN_DEFAULT_BUDGET : minBudgetModel.value

  emits('update-budget', max, min)
}

const clearBudget = (input: 'min' | 'max', withUpdate = true) => {
  if (input === 'min') {
    minBudgetModel.value = ''
    if (budgetMinRef.value) budgetMinRef.value.inputValue = ''
  } else {
    maxBudgetModel.value = ''
    if (budgetMaxRef.value) budgetMaxRef.value.inputValue = ''
  }

  if (withUpdate) updateBudget()
}

const handleDebounce = debounce(() => {
  updateBudget()
}, 1500)

watch([() => minBudget, () => maxBudget], ([newMin, newMax]) => {
  if (newMin === MIN_DEFAULT_BUDGET) clearBudget('min', false)
  if (newMax === MAX_DEFAULT_BUDGET) clearBudget('max', false)
})
</script>

<template>
  <div class="grid grid-cols-2 gap-4 md:mx-1">
    <div class="relative">
      <BaseFormComponentsBaseInput
        ref="budgetMinRef"
        v-model="minBudgetModel"
        :disabled="loadingFacets"
        :label="$t('search.priceFrom')"
        class="!px-2"
        id-form="search-budget"
        input-class="w-full"
        inputmode="numeric"
        name="minBudget"
        type="number"
        wrapper-class="override-input-number w-full"
        @update:model-value="handleDebounce"
      />
      <button
        v-if="minBudgetModel"
        class="absolute bottom-[18px] right-1"
        type="button"
        @click="clearBudget('min')"
      >
        <BaseIcon name="close" :size="1" class="text-gray-300" />
      </button>
    </div>
    <div class="relative">
      <BaseFormComponentsBaseInput
        ref="budgetMaxRef"
        v-model="maxBudgetModel"
        :disabled="loadingFacets"
        :label="$t('search.priceTo')"
        class="!px-2"
        id-form="search-budget"
        input-class="w-full"
        inputmode="numeric"
        name="maxBudget"
        type="number"
        wrapper-class="override-input-number w-full"
        @update:model-value="handleDebounce"
      />
      <button
        v-if="maxBudgetModel"
        class="absolute bottom-[18px] right-1"
        type="button"
        @click="clearBudget('max')"
      >
        <BaseIcon name="close" :size="1" class="text-gray-300" />
      </button>
    </div>
  </div>
</template>

<style>
.override-input-number label {
  @apply font-normal;
}
</style>
