<script setup lang="ts">
const localePath = useLocalePath()
</script>

<template>
  <div
    class="relative flex h-full flex-col gap-4 bg-secondary-100 px-7 py-14 text-sm"
  >
    {{ $t('search.criteria') }}
    <div class="font-bold">
      {{ $t('search.moreDetails') }}
    </div>
    <h6
      class="border-t border-gray-200 pt-8 font-sansSerif text-base text-secondary-500 xxl:mt-6 xxl:pt-6"
    >
      {{ $t('search.needHelp') }}
    </h6>
    <div class="flex w-fit flex-col gap-4">
      <TheMeetingSchedulerButton source="search page" with-icon />

      <BaseButton
        :to="localePath('contact')"
        class="!bg-transparent text-md hover:!bg-white"
        color="secondary"
        variant="outline"
      >
        <div class="flex items-center gap-4 sm:gap-4">
          <BaseIcon name="mail" />
          <span>{{ $t('global.contactUs') }}</span>
        </div>
      </BaseButton>
    </div>
  </div>
</template>
