export const formatFacets = ({ facetFiltersKey, facetFilters, locale }) => {
  const facetsArr = new Map()
  const str = (index) => {
    if (index === 'other_tags') {
      return `other_tags.${locale.value}`
    }

    return `searchable_tags.${index}.${locale.value}`
  }

  facetFiltersKey.value.forEach((key) => {
    if (facetFilters[key].length > 1) {
      facetFilters[key].forEach((facet) => {
        const nF = facet.split(`${str(key)}: `)[1]

        facetsArr.set(nF, `${str(key)}`)
      })
    } else {
      const nF = facetFilters[key][0].split(`${str(key)}: `)[1]

      facetsArr.set(nF, `${str(key)}`)
    }
  })

  return facetsArr
}

const hasCurrentLocale = ({ queryFacetFilter, locale, hasOne }) => {
  if (hasOne) {
    return queryFacetFilter.includes(`.${locale}:`)
  }

  return queryFacetFilter.some((facet) => facet.includes(`.${locale}:`))
}

export const getGroupIndex = (facetFilter) => {
  // Regex to check if string is includes of facet
  const tagGroupRegex = /tags\.(\d+)|(other_tags)/
  const isAdminFacet = facetFilter.includes('other_tags')
  const matchIndex = isAdminFacet ? 0 : 1
  const groupIndex = isAdminFacet
    ? facetFilter.match(tagGroupRegex)[matchIndex]
    : Number(facetFilter.match(tagGroupRegex)[matchIndex])

  return groupIndex
}

export const createFacetFilters = (query, locale) => {
  const queryFacetFilter = query.facetFilter
  const hasOnlyOneFacet = typeof queryFacetFilter === 'string'

  const facetFilters = reactive({})
  const facetFiltersDecoded = ref('')
  const facetFiltersKey = ref([])
  const facetsFiltersParams = ref([])

  if (
    queryFacetFilter &&
    hasCurrentLocale({
      queryFacetFilter,
      locale: locale.value,
      hasOne: hasOnlyOneFacet,
    })
  ) {
    if (hasOnlyOneFacet) {
      facetFiltersDecoded.value = queryFacetFilter.split('+').join(' ')

      const groupIndex = getGroupIndex(facetFiltersDecoded.value)

      facetFilters[groupIndex] = [facetFiltersDecoded.value]
      facetsFiltersParams.value.push(facetFiltersDecoded.value)
      facetFiltersKey.value.push(groupIndex)
    } else {
      facetFiltersDecoded.value = queryFacetFilter.map((facet) =>
        facet.split('+').join(' '),
      )

      facetFiltersDecoded.value.forEach((facetFilter) => {
        // searchable_tags.1.en => 1
        const groupIndex = getGroupIndex(facetFilter)

        if (facetFilters[groupIndex]) {
          facetFilters[groupIndex].push(facetFilter)
        } else {
          facetFilters[groupIndex] = [facetFilter]
          facetFiltersKey.value.push(groupIndex)
        }
      })

      facetsFiltersParams.value = facetFiltersKey.value
        .map((groupIndex) => facetFilters[groupIndex])
        .reduce((acc, val) => acc.concat(val), [])

      // Flat facetFiltersDecoded
      facetFiltersDecoded.value
        .map((facet) => facet.split(','))
        .reduce((acc, val) => acc.concat(val), [])
    }
  }

  return {
    facetFiltersKey,
    facetsFiltersParams,
    facetFilters,
    newFacetFilters: formatFacets({ facetFiltersKey, facetFilters, locale }),
  }
}
