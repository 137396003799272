import { algolia } from 'lc-services/services/algolia'

export const getDestinationHousesCount = ({
  config,
  query,
  filters,
}: {
  config: any
  query: string
  filters?: string
}) => {
  const defaultFilter = 'NOT houses_count=0 AND NOT state:hidden'

  return algolia({
    apiKey: config.public.algoliaSearchKey,
    appId: config.public.algoliaId,
    prefix: config.public.algoliaIndexPrefix,
  }).simpleQuery({
    indexName: 'public_destinations',
    query: query,
    params: {
      attributesToRetrieve: ['houses_count', 'id'],
      hitsPerPage: 20,
      responseFields: ['hits'],
      filters: filters?.length ? filters : defaultFilter,
    },
  })
}
